<template>
  <v-container>
    <v-row align="center">
      <v-col cols="12">
        <v-card>
          <v-data-table
            :headers="selectHeaders"
            :items="filteredUsers"
            :search="search"
            disable-pagination
            hide-default-footer
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title> 用户列表/User List</v-toolbar-title>
                <v-spacer></v-spacer>

                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                ></v-text-field>
                <v-dialog persistent v-model="userEditDialog" max-width="400px">
                  <v-card>
                    <v-card-title>
                      <span class="headline">编辑用户/Edit User - {{ editUser.realname }}</span>
                    </v-card-title>

                    <v-card-text>
                      <v-select
                        v-model="editUser.role"
                        label="Role/角色"
                        :items="selectRoles"
                        item-text="desc"
                        item-value="value"
                        required
                      >
                      </v-select>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="userEditClose"> Cancel </v-btn>
                      <v-btn color="blue darken-1" text @click="userEditSave"> Save </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-dialog persistent v-model="userDeleteDialog" max-width="400px">
                  <v-card>
                    <v-card-title class="headline">目前不支持直接删除用户</v-card-title>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="userDeleteClose">Cancel</v-btn>
                      <v-btn color="blue darken-1" text @click="userDeleteConfirm">OK</v-btn>
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>

              <v-row class="ma-2"
                ><v-col>
                  <v-select
                    v-model="selectHeaders"
                    :items="usersHeaders"
                    label="选择显示内容/Select Content"
                    item-value="value"
                    item-text="text"
                    return-object
                    multiple
                    chips
                    dense
                  ></v-select>
                </v-col>
              </v-row>
              <v-row class="ma-2" dense align="center" justify="center">
                <v-col>
                  <v-btn @click="exportAllGrade()"
                    ><v-icon>mdi-table-arrow-right</v-icon> 导出评分表</v-btn
                  >
                </v-col>
                <v-col>
                  <v-checkbox v-model="onlyProfessors" label="只看评委"></v-checkbox>
                </v-col>
              </v-row>
            </template>

            <v-spacer></v-spacer>

            <template v-slot:[`item.actions`]="{ item }">
              <v-icon small class="mr-2" @click="userEdit(item)"> mdi-pencil </v-icon>

              <v-tooltip top v-if="item.role == 'professor'">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" @click="exportGrade(item)">
                    mdi-table-arrow-right
                  </v-icon>
                </template>
                <span>导出评分表/Export Grade</span>
              </v-tooltip>

              <v-icon small @click="userDelete(item)" disabled> mdi-delete </v-icon>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>



<script>
import requests from "@/http";

export default {
  data: () => ({
    search: "",
    onlyProfessors: false,
    usersHeaders: [
      {
        text: "用户名/Name",
        align: "start",
        value: "name",
      },
      {
        text: "角色/Role",
        value: "role",
      },
      {
        text: "姓名/RealName",
        value: "realname",
      },
      {
        text: "邮箱/Email",
        sortable: false,
        value: "email",
      },
      {
        text: "手机/Phone",
        sortable: false,
        value: "phone",
      },
      {
        text: "注册时间/Time",
        value: "time",
      },
      {
        text: "分组/Group",
        value: "group_name",
      },
      {
        text: "操作/Actions",
        sortable: false,
        value: "actions",
      },
    ],
    selectHeaders: [],
    selectRoles: [
      { desc: "教务/Admin", value: "admin" },
      { desc: "评委/Professor", value: "professor" },
      { desc: "学生/Student", value: "student" },
      { desc: "无/None", value: "none" },
    ],
    userEditDialog: false,
    userDeleteDialog: false,
    editUser: {
      name: "",
      role: "",
      realname: "",
      email: "",
      phone: "",
    },
    emptyUser: {
      name: "",
      role: "",
      realname: "",
      email: "",
      phone: "",
    },
    users: [],
    groups: {},
  }),
  created() {
    requests.get(`/api/admin/group/simple`).then((res) => {
      const groups = res.data.groups;
      groups.forEach((g) => {
        this.groups[g.id] = g.name;
      }),
        this.getAllUsers();
    });
    this.selectHeaders = Object.assign([], this.usersHeaders);
  },
  computed: {
    filteredUsers() {
      return this.users.filter((item) => {
        if (!this.onlyProfessors) {
          return true;
        }
        return item.role == "professor";
      });
    },
  },
  methods: {
    getAllUsers() {
      requests.get(`/api/admin/users`).then((res) => {
        const users = res.data.users;
        users.forEach((u) => {
          u.group_name = this.groups[u.group];
        });
        this.users = users;
      });
    },
    userEdit(item) {
      this.userEditDialog = true;
      this.editUser = item;
    },
    userDelete(item) {
      this.userDeleteDialog = true;
      this.editUser = item;
    },
    userEditClose() {
      this.userEditDialog = false;
      this.$nextTick(() => {
        this.editUser = Object.assign({}, this.emptyUser);
      });
    },
    userEditSave() {
      requests
        .post(`/api/admin/users/role`, this.editUser)
        .then((res) => {
          alert(res.data.message);
          this.userEditClose();
        })
        .catch((err) => {
          alert(err.response.data.detail);
        });
    },
    userDeleteClose() {
      this.userDeleteDialog = false;
      this.$nextTick(() => {
        this.editUser = Object.assign({}, this.emptyUser);
      });
    },
    userDeleteConfirm() {
      this.userDeleteClose();
    },

    adminFileDownload(token) {
      const url = `/api/admin/download?token=${token}`;
      const link = document.createElement("a");
      link.href = url;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    exportGrade(item) {
      requests.post(`/api/admin/grade/export/professor/${item.id}`).then((res) => {
        this.adminFileDownload(res.data.token);
      });
    },
    exportAllGrade() {
      requests.post(`/api/admin/grade/export/all`).then((res) => {
        this.adminFileDownload(res.data.token);
      });
    },
  },
};
</script>
